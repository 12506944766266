import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import withAdminAuth from "../components/withAdminAuth";

const Search = ({ user }) => {
  const [searchLogs, setSearchLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSearches, setTotalSearches] = useState(0);
  const [searchesInLast24Hours, setSearchesInLast24Hours] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get("tab") || "users";

  // Fetch search logs if the tab is 'search-logs'
  useEffect(() => {
    const page = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(page);

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/analytics/search-logs`, {
        params: { limit: 30, page },
        withCredentials: true,
      })
      .then((response) => {
        const { logs, totalSearches, searchesInLast24Hours, totalCount } = response.data;
        setSearchLogs(logs || []); // Fallback to an empty array if data is undefined
        setTotalSearches(totalSearches);
        setSearchesInLast24Hours(searchesInLast24Hours);
        setTotalPages(Math.ceil(totalCount / 30));
      })
      .catch((error) => {
        console.error("Error fetching search logs:", error);
      });
  }, [currentTab, searchParams]);

  const changePage = (page) => {
    setSearchParams({ tab: currentTab, page });
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "y", seconds: 60 * 60 * 24 * 365 },
      { unit: "mth", seconds: 60 * 60 * 24 * 30 },
      { unit: "d", seconds: 60 * 60 * 24 },
      { unit: "h", seconds: 60 * 60 },
      { unit: "m", seconds: 60 },
      { unit: "s", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count}${unit}${count > 1 ? "" : ""}`;
      }
    }
    return "just now";
  };

  if (!user) {
    return <div>You are not authorized to access this page.</div>;
  }

  return (
    <div className="w-full px-3 py-4">
      <div className="max-w-screen-xl w-full mx-auto">
        <div>
          <h2 className="text-lg font-medium mb-4">
            AI tool search{" "}
            <span className="text-xs">
              (24h: {searchesInLast24Hours} / total: {totalSearches})
            </span>
          </h2>
          <ul>
            {searchLogs.map((log) => (
              <li key={log._id} className="py-2 border-b">
                <div className="flex justify-between">
                  <div className="text-xs flex space-x-2">
                    <div className="text-gray-500 border-r h-full pr-2 w-8">{getTimeAgo(log.timestamp)}</div>
                    <div>
                      <div>{typeof log.query === "object" ? JSON.stringify(log.query) : log.query}</div>
                      <div className="text-gray-500">
                        {log.userId && `${log.displayName} (${log.userId})`} {log.ipAddress || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex space-x-0.5 my-4">
          <button
            disabled={currentPage <= 1}
            onClick={() => changePage(currentPage - 1)}
            className={`px-2 py-1 rounded-l-lg text-sm ${
              currentPage <= 1 ? "bg-gray-300" : "bg-white-500 text-black border"
            }`}
          >
            Previous
          </button>
          <button
            disabled={currentPage >= totalPages}
            onClick={() => changePage(currentPage + 1)}
            className={`px-2 py-1 rounded-r-lg text-sm ${
              currentPage >= totalPages ? "bg-gray-300" : "bg-white-500 text-black border"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default withAdminAuth(Search);
