import React from "react";
import { Link } from "react-router-dom";
import SigninModal from "./SigninModal";
import SettingsButton from "./SettingsButton";

const NavBar = ({ user, setUser, signinModalOpen, setSigninModalOpen, searchable }) => {
  return (
    <nav className={`w-full flex-shrink-0 flex flex-col items-center z-30 py-3 p-3 bg-white`}>
      <SigninModal open={signinModalOpen} setOpen={setSigninModalOpen} />
      <div className="flex max-w-screen-xl w-full h-full justify-between space-x-2">
        <div className="flex space-x-6 w-full items-center">
          <Link to="/" className="flex-shrink-0 font-semibold">
            Raghut Admin
          </Link>
        </div>

        <div className="flex flex-shrink-0 font-medium space-x-2">
          {user ? (
            <SettingsButton user={user} setUser={setUser} />
          ) : (
            <button
              onClick={() => setSigninModalOpen(true)}
              className="text-sm md:text:base bg-white border border-gray-500 h-9 px-3 flex-shrink-0"
            >
              Sign in
            </button>
          )}
        </div>
      </div>
      <div className="flex max-w-screen-xl w-full h-full justify-between space-x-2 mt-2 overflow-x-auto">
        <div>
          {user && (
            <div className="flex items-center space-x-2">
              <Link to="/?page=1">
                <div className="text-sm border px-3 py-1 rounded-lg flex-shrink-0">Users</div>
              </Link>
              <Link to="/tool-search?page=1">
                <div className="text-sm border px-3 py-1 rounded-lg flex-shrink-0">
                  <p className=" whitespace-nowrap">Tool search</p>
                </div>
              </Link>
              <Link to="/search-engine">
                <div className="text-sm border px-3 py-1 rounded-lg flex-shrink-0">Engines</div>
              </Link>
              <Link to="/documents">
                <div className="text-sm border px-3 py-1 rounded-lg flex-shrink-0">Documents</div>
              </Link>
              <Link to="/queue">
                <div className="text-sm border px-3 py-1 rounded-lg flex-shrink-0">Queue</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
