import React, { useState, useEffect } from "react";
import { Table, Badge, Button, Space, Typography, message, Modal } from "antd";
import {
  ReloadOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { FiClock } from "react-icons/fi";
import axios from "axios";
import withAdminAuth from "../components/withAdminAuth";

const { Text } = Typography;
const API_URL = process.env.REACT_APP_API_URL;

const Queue = () => {
  const [queueStatus, setQueueStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobDetailsVisible, setJobDetailsVisible] = useState(false);

  const fetchQueueStatus = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${API_URL}/api/search-engines/queue/status`, {
        withCredentials: true,
      });
      setQueueStatus(data);
    } catch (err) {
      message.error(err.response?.data?.error || "Failed to fetch queue status");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQueueStatus();
    const interval = setInterval(fetchQueueStatus, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleRetryJob = async (jobId) => {
    try {
      await axios.post(
        `${API_URL}/api/search-engines/queue/jobs/${jobId}/retry`,
        {},
        {
          withCredentials: true,
        }
      );
      message.success("Job queued for retry");
      fetchQueueStatus();
    } catch (err) {
      message.error(err.response?.data?.error || "Failed to retry job");
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await axios.delete(`${API_URL}/api/search-engines/queue/jobs/${jobId}`, {
        withCredentials: true,
      });
      message.success("Job deleted successfully");
      fetchQueueStatus(); // Refresh the queue status
    } catch (err) {
      message.error(err.response?.data?.error || "Failed to delete job");
    }
  };

  const handleCleanQueue = async () => {
    try {
      await axios.post(
        `${API_URL}/api/search-engines/queue/clean`,
        {},
        {
          withCredentials: true,
        }
      );
      message.success("Queue cleaned successfully");
      fetchQueueStatus();
    } catch (err) {
      message.error(err.response?.data?.error || "Failed to clean queue");
    }
  };

  const handleCleanStuck = async () => {
    try {
      await axios.post(
        `${API_URL}/api/search-engines/queue/force-remove-stuck`,
        {},
        {
          withCredentials: true,
        }
      );
      message.success("Queue cleaned successfully");
      fetchQueueStatus();
    } catch (err) {
      message.error(err.response?.data?.error || "Failed to clean queue");
    }
  };
  const handleCleanQueueAll = async () => {
    try {
      await handleCleanStuck();
      await axios.post(
        `${API_URL}/api/search-engines/queue/clean/all`,
        {},
        {
          withCredentials: true,
        }
      );
      message.success("Queue cleaned successfully");
      fetchQueueStatus();
    } catch (err) {
      message.error(err.response?.data?.error || "Failed to clean queue");
    }
  };

  const getStatusBadge = (state) => {
    const statusConfig = {
      completed: { status: "success", icon: <CheckCircleOutlined /> },
      active: { status: "processing", icon: <SyncOutlined spin /> },
      waiting: { status: "warning", icon: <ClockCircleOutlined /> },
      failed: { status: "error", icon: <ExclamationCircleOutlined /> },
      delayed: { status: "default", icon: <FiClock /> },
    };

    const config = statusConfig[state] || statusConfig.default;
    return <Badge status={config.status} text={state} icon={config.icon} />;
  };

  const viewJobDetails = async (jobId) => {
    try {
      const { data } = await axios.get(`${API_URL}/api/search-engines/queue/jobs/${jobId}`, {
        withCredentials: true,
      });
      setSelectedJob(data);
      setJobDetailsVisible(true);
    } catch (err) {
      message.error(err.response?.data?.error || "Failed to fetch job details");
    }
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "y", seconds: 60 * 60 * 24 * 365 },
      { unit: "mth", seconds: 60 * 60 * 24 * 30 },
      { unit: "d", seconds: 60 * 60 * 24 },
      { unit: "h", seconds: 60 * 60 },
      { unit: "m", seconds: 60 },
      { unit: "s", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count}${unit}${count > 1 ? "" : ""}`;
      }
    }
    return "just now";
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      width: 120,
      render: (state) => getStatusBadge(state),
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: 100,
      render: (progress) => `${progress}%`,
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "timestamp",
      width: 180,
      render: (timestamp) => getTimeAgo(timestamp),
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => viewJobDetails(record.id)}>
            Details
          </Button>
          {record.state === "failed" && (
            <>
              <Button
                type="link"
                icon={<RedoOutlined />}
                onClick={() => handleRetryJob(record.id)}
                className="text-red-500"
              >
                Retry
              </Button>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteJob(record.id)}
                className="text-red-500"
              >
                Delete
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="w-full px-3 py-4">
      <div className="max-w-screen-xl w-full mx-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-medium mb-2">Queue monitor</h2>
          <div className="flex items-center space-x-1.5">
            <Button type="primary" icon={<ReloadOutlined />} onClick={fetchQueueStatus} loading={loading}></Button>
            <Button icon={<DeleteOutlined />} onClick={handleCleanQueue} className="bg-red-50"></Button>
            <button onClick={handleCleanQueueAll} className="bg-red-500 text-white px-3 py-1.5 rounded text-sm">
              HARD
            </button>
          </div>
        </div>

        {queueStatus?.summary && (
          <div className="flex mb-4 space-x-1">
            {Object.entries(queueStatus.summary).map(([key, value]) => (
              <div key={key} className="text-xs">
                <span>{key.charAt(0).toUpperCase() + key.slice(1)}</span>
                <span className="ml-1 font-medium">{value} / </span>
              </div>
            ))}
          </div>
        )}

        <div className="overflow-x-auto -mx-3">
          <div className="min-w-full inline-block align-middle px-3">
            <Table
              size="small"
              dataSource={queueStatus?.recentJobs || []}
              columns={columns}
              rowKey="id"
              loading={loading}
              pagination={false}
              scroll={{ x: "max-content" }} // Enable horizontal scrolling
              className="whitespace-nowrap" // Prevent text wrapping
            />
          </div>
        </div>

        <Modal
          title="Job Details"
          open={jobDetailsVisible}
          onCancel={() => setJobDetailsVisible(false)}
          footer={null}
          width={800}
        >
          {selectedJob && (
            <div className="space-y-4">
              <div>
                <Text strong>Job ID: </Text>
                <Text>{selectedJob.id}</Text>
              </div>
              <div>
                <Text strong>Status: </Text>
                {getStatusBadge(selectedJob.state)}
              </div>
              <div>
                <Text strong>Progress: </Text>
                <Text>{selectedJob.progress}%</Text>
              </div>
              <div>
                <Text strong>Timestamp: </Text>
                <Text>{new Date(selectedJob.timestamp).toLocaleString()}</Text>
              </div>
              {selectedJob.failedReason && (
                <div>
                  <Text strong>Error: </Text>
                  <Text type="danger">{selectedJob.failedReason}</Text>
                </div>
              )}
              <div>
                <Text strong>Data: </Text>
                <pre className="bg-gray-50 p-4 rounded-md overflow-auto mt-2">
                  {JSON.stringify(selectedJob.data, null, 2)}
                </pre>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default withAdminAuth(Queue);
