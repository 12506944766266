import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import withAdminAuth from "../components/withAdminAuth";

const Documents = ({ user }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
    },
    sorter: {
      field: "uploadedAt",
      order: "descend",
    },
  });

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/analytics/documents?page=${tableParams.pagination.current}&limit=${
            tableParams.pagination.pageSize
          }&sortBy=${tableParams.sorter.field}&sortOrder=${tableParams.sorter.order === "ascend" ? "asc" : "desc"}`,
          { credentials: "include" }
        );

        if (!response.ok) throw new Error("Failed to fetch documents");

        const responseData = await response.json();
        setData(responseData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [tableParams]);

  const retryDocument = async (jobId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/queue/jobs/${jobId}/retry`, {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to retry document");
      }

      message.success("Document retry initiated successfully");
      setTableParams({ ...tableParams }); // Trigger a data refresh
    } catch (err) {
      message.error(`Retry failed: ${err.message}`);
    }
  };

  const columns = [
    {
      title: "Time",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      sorter: true,
      width: 80,
      render: (text) => getTimeAgo(text),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const handleDownload = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/document-search/download/${record._id}`,
              {
                credentials: "include",
              }
            );

            if (!response.ok) {
              throw new Error("Failed to get download URL");
            }

            const data = await response.json();

            // Create a hidden anchor and trigger download
            const link = document.createElement("a");
            link.href = data.downloadUrl;
            link.download = data.filename || text;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            console.error("Download error:", error);
          }
        };

        return (
          <button
            onClick={handleDownload}
            className="text-blue-600 hover:text-blue-800 hover:underline text-left max-w-96 truncate"
          >
            {text}
          </button>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      render: (text) => {
        const mimeType = text.toLowerCase();
        if (mimeType.includes("wordprocessingml")) return "DOCX";
        if (mimeType.includes("pdf")) return "PDF";
        if (mimeType.includes("text/plain")) return "TXT";
        if (mimeType.includes("jpeg")) return "JPG";
        if (mimeType.includes("png")) return "PNG";
        return mimeType.split("/").pop().split(".").pop().toUpperCase();
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (status, record) => (
        <span
          className={`px-2 py-1 rounded text-xs ${
            status === "indexed"
              ? "bg-green-100 text-green-800"
              : status === "processing"
              ? "bg-yellow-100 text-yellow-800"
              : status === "failed"
              ? "bg-red-100 text-red-800"
              : "bg-gray-100 text-gray-800"
          }`}
        >
          {status}
          {status === "failed" && (
            <button type="link" className="ml-2 text-blue-500" onClick={() => retryDocument(record.jobId)}>
              Retry
            </button>
          )}
        </span>
      ),
    },
    {
      title: "Uploader",
      dataIndex: ["uploader", "displayName"],
      key: "uploader",
      width: 150,
    },
  ];

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "y", seconds: 60 * 60 * 24 * 365 },
      { unit: "mth", seconds: 60 * 60 * 24 * 30 },
      { unit: "d", seconds: 60 * 60 * 24 },
      { unit: "h", seconds: 60 * 60 },
      { unit: "m", seconds: 60 },
      { unit: "s", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count}${unit}${count > 1 ? "" : ""}`;
      }
    }
    return "just now";
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      sorter: {
        field: sorter.field || "uploadedAt",
        order: sorter.order || "descend",
      },
    });
  };

  if (!user) return <div>You are not authorized to access this page.</div>;
  if (error) return <div className="w-full px-6 py-4 text-red-500">Error: {error}</div>;

  const { metrics: stats } = data || { metrics: {} };

  return (
    <div className="w-full px-3 py-4">
      <div className="max-w-screen-xl w-full mx-auto">
        <h2 className="text-lg font-medium mb-2">Documents</h2>
        <div className="text-xs">
          <span>Total</span>
          <span className="ml-1 font-medium">{stats?.total || 0}</span>
          <span> / Process</span>
          <span className="ml-1 text-yellow-600">{stats?.processing || 0}</span>
          <span> / Complet</span>
          <span className="ml-1 text-green-600">{stats?.completed || 0}</span>
          <span> / Fail</span>
          <span className="ml-1 text-red-600">{stats?.failed || 0}</span>
        </div>
        {/* Document Types Summary */}
        {stats?.byType && stats.byType.length > 0 && (
          <div className="mb-4 text-xs mt-1">
            {stats.byType.map((type) => {
              // Convert MIME type to simple format
              let simpleType = type._id.toLowerCase();
              if (simpleType.includes("wordprocessingml")) {
                simpleType = "docx";
              } else if (simpleType.includes("pdf")) {
                simpleType = "pdf";
              } else if (simpleType.includes("text/plain")) {
                simpleType = "txt";
              } else if (simpleType.includes("jpeg")) {
                simpleType = "jpg";
              } else if (simpleType.includes("png")) {
                simpleType = "png";
              } else {
                // Extract extension from MIME type
                simpleType = simpleType.split("/").pop().split(".").pop().toUpperCase();
              }

              return (
                <span key={type._id} className="mr-1.5">
                  {simpleType} {type.count} /
                </span>
              );
            })}
          </div>
        )}
        <div className="overflow-x-auto -mx-3">
          <div className="min-w-full inline-block align-middle px-3">
            <Table
              size="small"
              dataSource={data?.documents || []}
              columns={columns}
              rowKey="_id"
              pagination={{
                ...tableParams.pagination,
                total: data?.pagination?.totalPages * tableParams.pagination.pageSize,
              }}
              onChange={handleTableChange}
              loading={loading}
              scroll={{ x: "max-content" }} // Enable horizontal scrolling
              className="whitespace-nowrap" // Prevent text wrapping
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAdminAuth(Documents);
