import React, { useState, useEffect } from "react";
import { Table } from "antd";
import withAdminAuth from "../components/withAdminAuth";

const SearchEngine = ({ user }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
    },
    sorter: {
      field: "createdAt",
      order: "descend",
    },
  });

  useEffect(() => {
    const fetchSearchEngines = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/analytics/search-engines?page=${tableParams.pagination.current}&limit=${
            tableParams.pagination.pageSize
          }&sortBy=${tableParams.sorter.field}&sortOrder=${tableParams.sorter.order === "ascend" ? "asc" : "desc"}`,
          { credentials: "include" }
        );

        if (!response.ok) throw new Error("Failed to fetch search engines");

        const responseData = await response.json();
        setData(responseData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchEngines();
  }, [tableParams]);

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "y", seconds: 60 * 60 * 24 * 365 },
      { unit: "mth", seconds: 60 * 60 * 24 * 30 },
      { unit: "d", seconds: 60 * 60 * 24 },
      { unit: "h", seconds: 60 * 60 },
      { unit: "m", seconds: 60 },
      { unit: "s", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count}${unit}${count > 1 ? "" : ""}`;
      }
    }
    return "just now";
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text) => getTimeAgo(text),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a
          href={`https://www.raghut.com/dashboard/${record._id}/documents`}
          className="text-blue-600 hover:text-blue-800"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="max-w-28 truncate">{text}</div>
        </a>
      ),
    },
    {
      title: "Desc",
      dataIndex: "description",
      key: "description",
      render: (text) => <div className="max-w-sm truncate">{text}</div>,
    },
    {
      title: "Docs",
      dataIndex: ["metrics", "documentCount"],
      key: "documentCount",
      sorter: true,
    },
    {
      title: "Queries",
      dataIndex: ["metrics", "totalQueries"],
      key: "totalQueries",
      sorter: true,
    },
    {
      title: "Creator",
      dataIndex: ["creator", "displayName"],
      key: "creator",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      sorter: {
        field: sorter.field || "createdAt",
        order: sorter.order || "descend",
      },
    });
  };

  if (!user) return <div>You are not authorized to access this page.</div>;
  if (error) return <div className="w-full px-6 py-4 text-red-500">Error: {error}</div>;

  const { metrics: stats } = data || { metrics: {} };

  return (
    <div className="w-full px-3 py-4">
      <div className="max-w-screen-xl w-full mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-medium mb-4">
            Search Engines <span className="text-xs">({stats?.total || 0})</span>
          </h2>
        </div>

        <div className="overflow-x-auto -mx-3">
          <div className="min-w-full inline-block align-middle px-3">
            <Table
              size="small"
              dataSource={data?.searchEngines || []}
              columns={columns}
              rowKey="_id"
              pagination={{
                ...tableParams.pagination,
                total: data?.pagination?.totalPages * tableParams.pagination.pageSize,
              }}
              onChange={handleTableChange}
              loading={loading}
              scroll={{ x: "max-content" }} // Enable horizontal scrolling
              className="whitespace-nowrap" // Prevent text wrapping
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAdminAuth(SearchEngine);
