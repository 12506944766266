import { Modal } from "antd";
import React from "react";
import GoogleSignUp from "./GoogleSignUp";
import { TypeAnimation } from "react-type-animation";

const SigninModal = ({ setOpen, open }) => {
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        width={600}
        open={open}
        title=""
        closeIcon={null}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[]}
      >
        <div className="w-full h-full py-6 flex flex-col items-center space-y-6">
          <TypeAnimation sequence={["Sign up on Raghut"]} speed={50} className="text-2xl font-semibold" />
          <p className="text-center text-gray-500 text-lg max-w-md">
            Join Raghut to submit your AI tools and interact with individual tools to explore their capabilities.
          </p>
          <GoogleSignUp />
        </div>
      </Modal>
    </>
  );
};

export default SigninModal;
