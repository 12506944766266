// components/withAdminAuth.js
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message, Spin } from "antd";
import axios from "axios";

const withAdminAuth = (WrappedComponent) => {
  return function WithAdminAuthComponent(props) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);

    const adminIds = useMemo(
      () => [
        "6717001dd8156c68024973a9", // Andrew Jang (dhj9817@gmail.com)
        "6717001ed8156c68024973ae", // Andrew Jang (andrew@raghut.com)
        "6717b1fb6025dff18c721f33", // William Won (william@raghut.com)
      ],
      []
    );

    useEffect(() => {
      const checkAdminStatus = async () => {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`, {
            withCredentials: true, // Important for cross-domain requests with credentials
          });

          if (!data._id) {
            throw new Error("No user ID found");
          }

          if (!adminIds.includes(data._id)) {
            message.error("Unauthorized: Admin access required");
            navigate("/");
            return;
          }

          setIsAuthorized(true);
        } catch (error) {
          console.error("Auth check error:", error);

          if (error.response?.status === 401) {
            message.error("Please log in to continue");
            navigate("/login");
          } else {
            message.error("Error checking authorization");
            navigate("/");
          }
        } finally {
          setIsLoading(false);
        }
      };

      checkAdminStatus();
    }, [navigate, adminIds]);

    if (isLoading) {
      return <Spin size="large" className="flex justify-center items-center min-h-screen" />;
    }

    return isAuthorized ? <WrappedComponent {...props} /> : null;
  };
};

export default withAdminAuth;
