import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import withAdminAuth from "../components/withAdminAuth";

const Users = ({ user }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromParams = searchParams.get("page") || 1;

  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(Number(pageFromParams));
  const [totalUsers, setTotalUsers] = useState(0);

  // Sync `currentPage` with `searchParams` whenever `searchParams` changes
  useEffect(() => {
    const pageFromParams = searchParams.get("page") || 1;
    setCurrentPage(Number(pageFromParams));
  }, [searchParams]);

  const changePage = (page) => {
    setSearchParams({ page }); // Update the URL parameters
  };

  // Fetch users if the tab is 'users'
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/analytics/users`, {
        params: { page: currentPage, limit: 30 }, // Add pagination params
        withCredentials: true,
      })
      .then((response) => {
        const { users, totalUsers, totalPages } = response.data;
        setUsers(users);
        setTotalUsers(totalUsers); // Set total users
        setTotalPages(totalPages); // Set total pages
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [currentPage]);

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "y", seconds: 60 * 60 * 24 * 365 },
      { unit: "mth", seconds: 60 * 60 * 24 * 30 },
      { unit: "d", seconds: 60 * 60 * 24 },
      { unit: "h", seconds: 60 * 60 },
      { unit: "m", seconds: 60 },
      { unit: "s", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count}${unit}${count > 1 ? "" : ""}`;
      }
    }
    return "just now";
  };

  if (!user) {
    return <div>You are not authorized to access this page.</div>;
  }

  return (
    <div className="w-full px-3 py-4">
      <div className="max-w-screen-xl w-full mx-auto">
        <div>
          <h2 className="text-lg font-medium mb-4">
            Users <span className="text-xs">({users.length * currentPage} of {totalUsers})</span>
          </h2>

          <ul>
            {users.map((u) => (
              <li key={u._id} className="py-2 border-b">
                <div className="flex justify-between">
                  <div className="text-xs flex space-x-2">
                    <div className="text-gray-500 border-r h-full pr-2 w-8">{getTimeAgo(u.createdAt)}</div>
                    <div>
                      <div>{u.displayName}</div>
                      <div className="text-gray-500">{u.email}</div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="flex space-x-0.5 my-4">
            <button
              disabled={currentPage <= 1}
              onClick={() => changePage(currentPage - 1)}
              className={`px-2 py-1 rounded-l-lg text-sm ${
                currentPage <= 1 ? "bg-gray-300" : "bg-white-500 text-black border"
              }`}
            >
              Previous
            </button>
            <button
              disabled={currentPage >= totalPages}
              onClick={() => changePage(currentPage + 1)}
              className={`px-2 py-1 rounded-r-lg text-sm ${
                currentPage >= totalPages ? "bg-gray-300" : "bg-white-500 text-black border"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAdminAuth(Users);
